import { isPC } from "../../../../common/utils/util"

/**
 * 隐私协议
 */
export default {

    data() {
        return {
            space2: "\xa0\xa0\xa0\xa0",
            isPc: isPC()
        }
    },

    created() {
    },

    methods: {

    },


}
